import React from 'react'
import styled from 'styled-components'
import Navigation from './Navigation'
import ContactForm from './ContactForm'
import { StaticQuery, Link } from 'gatsby'

const FooterWrapper = styled.section`
  margin-top: 2rem;

  a {
    color: inherit;
    text-decoration: none;
  }

  .inner {
    display: flex;
    justify-content: space-between;
    align-items: top;
    padding: 3rem;
  }

  margin: 0rem auto;
  max-width: 1300px;

  h1 {
    margin: 0;
  }

  .stacked {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    h3 {
      margin: 0;
      font-size: 14px;
      text-transform: uppercase;
      margin-bottom: 10px;
    }

    section {
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 0;

      a {
        margin: 0;
        font-weight: normal;
      }
    }
  }

  .single {
    margin: 0;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
    color: inherit;
    text-decoration: none;
    margin-bottom: 10px;
  }
`

const SubFooter = styled.div`
  border-top: 1px solid #ccc;
  width: 100%;
  padding: 1rem;
  color: #999;
  font-size: 12px;
`

export default () => {
  const year = new Date().getFullYear()

  return (
    <FooterWrapper>
      <div className="inner">
        <div>
          <div className="stacked">
            <h3>Our Business</h3>
            <Navigation />
          </div>
          <br />
          <div className="stacked">
            <StaticQuery
              query={sliderQuery}
              render={data =>
                data.allWordpressPost.edges.map(({ node: slide }, index) => (
                  <Link className="single" key={index} to={slide.slug}>
                    {slide.title}
                  </Link>
                ))
              }
            />
          </div>
        </div>

        <div className="stacked">
          <h3>Mega Techway, Inc</h3>
          <p>
            760 F Beta Drive, <br />
            Mayfield Village, OH 44143
            <br />
            440 605 0700 |{' '}
            <a href="mailto:info@megatechway.com">info@megatechway.com</a>
          </p>
        </div>

        <ContactForm />
      </div>
      <SubFooter>
        Copyright &copy; {year} Mega Techway, Inc. All rights reserved.
      </SubFooter>
    </FooterWrapper>
  )
}

const sliderQuery = graphql`
  query {
    allWordpressPost(
      filter: { categories: { elemMatch: { name: { eq: "slider" } } } }
    ) {
      edges {
        node {
          id
          title
          slug
        }
      }
    }
  }
`
