import React from 'react'
import { Link } from 'gatsby'
import { StaticQuery, graphql } from 'gatsby'

import styled from 'styled-components'

const Menu = styled.section`
  /* display: block;
  min-width: 2000px;
  margin: 0rem auto;
  margin-top: -0.3rem; */

  a {
    color: inherit;

    text-decoration: none;
    margin-left: 2rem;
    font-weight: 500;
    font-size: 18px;
  }
`

export default ({ menus }) => (
  <StaticQuery
    query={graphql`
      query {
        wp {
          menus(where: { id: 3 }) {
            nodes {
              id
              menuId
              menuItems {
                edges {
                  node {
                    id
                    label
                    url

                    linkRelationship
                    childItems {
                      nodes {
                        menuItemId
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={({ wp }) => (
      <Menu>
        {wp.menus.nodes[0].menuItems.edges.map(menuItem => {
          const url = menuItem.node.url.split('/')

          return (
            <Link key={menuItem.node.id} to={url[3]}>
              {menuItem.node.label}
            </Link>
          )
        })}
      </Menu>
    )}
  />
)
