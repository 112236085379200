import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import logo from '../images/mti_logo.png'

const Logo = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  span {
    font-size: 15px;
    font-weight: normal;
  }
  img {
    height: 56px;
  }
`

export default ({ title }) => (
  <Logo>
    <Link to="/">
      <img src={logo} alt={title} />
    </Link>
    {/* <span>Interconnection Solutions</span> */}
  </Logo>
)
