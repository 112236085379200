import PropTypes from 'prop-types'
import React from 'react'

import styled from 'styled-components'
import Logo from './Logo'
import Navigation from './Navigation'

const HeaderWrapper = styled.section`
  margin: 2.5vh auto;
  margin-bottom: 4vh;

  display: flex;
  /* align-items: center; */
  /* padding: 1rem 0; */
  /* justify-content: space-between; */

  h1 {
    margin: 0;
    margin-top: 5px;
  }

  .wrapper {
    width: 100%;
    max-width: 95vw;
    margin: 0 auto;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .focus {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    margin-top: -10px;
  }

  .bottom {
    margin: 0 auto;
    padding: 4px;
  }

  .contact {
    font-weight: 400;
    font-size: 14px;

    margin-bottom: 0.75rem;
    position: relative;
    a {
      color: #555;
      margin-left: 1rem;
      text-decoration: none;

      svg {
        fill: #555;
        width: 16px;
        position: relative;
        top: 7px;
      }
    }
  }
`

const Header = ({ siteTitle = '', menus }) => (
  <>
    {/* <Contact>
      <div className="inner">
        <a href="tel:4406050700">440.605.0700</a>
        <a href="mailto:info@megatechway.com">info@megatechway.com</a>
        <a href="https://www.linkedin.com/company/mega-techway/about/">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
          </svg>
        </a>
      </div>
    </Contact> */}

    <HeaderWrapper>
      <div className="wrapper">
        <h1>
          <Logo title={siteTitle} />
        </h1>

        <div className="focus">
          <div className="contact">
            <a href="tel:4406050700">440.605.0700</a>
            <a href="mailto:info@megatechway.com">info@megatechway.com</a>
            <a href="https://www.linkedin.com/company/mega-techway/about/">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
              </svg>
            </a>
          </div>
          <Navigation menus={menus} />
        </div>
      </div>
    </HeaderWrapper>
  </>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: `MegaTech Way, Inc.`,
}

export default Header
