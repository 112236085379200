import React from 'react'

import styled from 'styled-components'

const ContactWrappr = styled.section`
  form {
    h3 {
      text-transform: uppercase;
      margin: 0;
      font-size: 22px;
      margin-bottom: 12px;
    }

    h4 {
      font-size: 16px;
      font-weight: 400;
    }

    max-width: 750px;
    margin: 0 auto;

    .inputGroup {
      display: flex;
      margin-bottom: 1rem;
      justify-content: space-between;

      input {
        flex: 1;
        width: 100%;
        padding: 3px 5px;
        font-size: 14px;

        &:first-child {
          margin-right: 1rem;
        }
      }
    }

    textarea {
      width: 100%;
      min-height: 75px;
      padding: 5px;
      font-size: 14px;
    }
  }
`

const Button = styled.button`
  background: #333;
  color: #fff;
  padding: 6px 24px;
  font-size: 14px;

  font-weight: bold;
  border-radius: 3px;
  border: 0;
`

export default () => (
  <ContactWrappr>
    <form>
      <h3>Ready to start a new project?</h3>
      <h4>Fill out the form below and we will reach out to you.</h4>
      <div className="inputGroup">
        <input placeholder="Name" />
        <input placeholder="Company" />
      </div>
      <div className="inputGroup">
        <input placeholder="Email" />
        <input placeholder="Phone" />
      </div>

      <textarea placeholder="Your Message" />
      <br />

      <Button>Submit</Button>
    </form>
  </ContactWrappr>
)
